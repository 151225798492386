<template lang="pug">
q-input.full-width(
  ref="formInput"
    outlined,
    v-model="inputVal",
    :disable="disable"
    lazy-rules
    :label="$t(fieldname)",
    :rules="[(val) => !!val || $t(verifiedname)]"
  )
  template(v-slot:before)
    q-icon(:name="icon" size="lg")
</template>

<script>
export default {
  props: ["value", "icon", "name", "disable"],
  methods: {
    async validate() {
      return await this.$refs.formInput.validate();
    },
  },
  computed: {
    fieldname() {
      return `${this.name}_title`;
    },
    verifiedname() {
      return `${this.name}_not_verified`;
    },
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
};
</script>
