<template lang="pug">
q-file.full-width(
  ref="formInput",
  v-model="inputVal",
  :label="$t('select_video')",
  outlined,
  accept=".mp4, video/*",
  :disable="disable",
  lazy-rules,
  @rejected="$emit('rejected')",
  :rules="[(val) => !!val || $t('video_required')]"
)
  template(v-slot:prepend)
    q-icon(:name="matMovie", size="sm" color="primary")
  template(v-slot:append)
    q-btn(flat :icon="matUpload" dense round color="black" @click="getFile").bg-secondary
</template>

<script>
export default {
  props: ["value", "icon", "name", "disable"],
  methods: {
    async validate() {
      return await this.$refs.formInput.validate();
    },
    getFile () {
      this.$refs.formInput.$el.click();
    }
  },
  computed: {
    fieldname() {
      return `${this.name}_title`;
    },
    verifiedname() {
      return `${this.name}_not_verified`;
    },
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
};
</script>
