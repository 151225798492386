<template lang="pug">
div
  div(v-for="(vid,n) in numberOfVideos")
    div(v-if="canUpload(vid-1)")
      .text-center.q-my-lg
        //- q-icon(:name="matEmojiObjects", size="lg")
        .text-h6.text-primary {{n+1}}. {{ $t(`phase_${phase}_info_title_${vid}`) }}
        .text-body1.text-grey-9 {{ $t(`phase_${phase}_info_description_${vid}`) }}
      FormFileInput(
        :ref="`field${vid-1}`",
        v-on:rejected="$emit('rejected')",
        v-model="files[vid-1]"
      )

  //- Team
  div(v-if="!submission.id && !loading && hasTeam")
    .text-center.q-my-lg
      q-icon(:name="matGroups", size="lg")
      .text-h6 {{ $t('myteam') }}
      .text-body1 {{ $t('team_text') }}

    .row
      .col-auto
        q-icon.on-left.q-pt-sm(:name="matGroup", size="lg", color="grey-7")
      .col.q-py-sm.full-width
        .fieldborder.rounded-borders
          q-list(separator)
            q-item.q-my-sm
              q-item-section
                q-item-label {{ user.displayName }}
                q-item-label(caption) {{ user.email }}
            q-item.q-my-sm(
              v-for="(person, index) in team",
              :key="person.email"
            )
              q-item-section
                q-item-label {{ person.name }}
                q-item-label(caption) {{ person.email }}
              q-item-section(side)
                q-btn(
                  :icon="matDelete",
                  round,
                  flat,
                  @click="removePerson(index)"
                )
            q-item.q-my-sm
              q-item-section
                q-input(
                  dense,
                  v-model="newperson.name",
                  ref="name",
                  flat,
                  :label="$t('team_name')",
                  :error="nameinvalid",
                  :error-message="$t('requires_name')"
                )
              q-item-section
                q-input(
                  dense,
                  v-model="newperson.email",
                  flat,
                  ref="email",
                  :label="$t('team_email')",
                  type="email",
                  :error="emailinvalid",
                  :error-message="$t('requires_email')"
                )
              q-item-section(side)
                q-btn(@click="addPerson()", flat, round, :icon="matAdd")
</template>

<script>
import FormTextInput from "@/components/FormTextInput";
import FormFileInput from "@/components/FormFileInput";
import { mapState } from "vuex";
import { find } from "lodash";

export default {
  name: "PhaseSubmission",
  components: {
    FormTextInput,
    FormFileInput,
  },
  data: () => {
    return {
      newperson: {
        name: "",
        email: "",
      },
      team: [],
      nameinvalid: false,
      emailinvalid: false,
    };
  },
  methods: {
    // validateMe() {
    //   for (let vid = 0; vid < this.numberOfVideos; vid++) {
    //     console.log("validate", `field${vid}`);
    //     // if (!this.$refs[`field${vid}`].validate()) return false;
    //   }
    //   return true;
    // },
    removePerson(index) {
      this.team.splice(index, 1);
    },
    addPerson() {
      if (!this.newperson.name) this.nameinvalid = true;
      if (!this.newperson.email) this.emailinvalid = true;

      if (this.newperson.name && this.newperson.email) {
        this.team.push(this.newperson);
        this.newperson = { name: "", email: "" };
        this.$refs.email.resetValidation();
        this.$refs.name.resetValidation();
      }
    },
    canUpload(index) {
      if (!this.neededFiles) return true;
      else if (this.neededFiles[index]) return true;
      else return false;
    },
  },
  watch: {
    team: {
      deep: true,
      handler() {
        this.submission.team = this.team;
      },
    },
  },
  computed: {
    ...mapState(["user"]),
    hasTeam() {
      return find(this.config.phases, { code: this.phase }).team || false;
    },
    numberOfVideos() {
      return parseInt(
        find(this.config.phases, { code: this.phase }).noOfVideos
      );
    },
  },
  props: [
    "files",
    "submission",
    "languageList",
    "commsLanguages",
    "countryList",
    "neededFiles",
    "loading",
    "phase",
    "config",
  ],
};
</script>

<style lang="scss" scoped>
// @import "@/styles/quasar.variables.scss";
.fieldborder {
  border-color: #bdbdbd;
  border-width: 1px;
  border-style: solid;
}
</style>
